import { post, get } from '@http/axios'
export const getApiList = () => post('/api/common/public/routelist')
export const login = (params) => post('/login', params)

export const getOssToken = (params) => get('/api/ali-oss/security', { params })

// 获取医生信息
export const getDoctorInfo = () => get('api/doctor-platform/doctor/info')

// 操作日志
export const getOplogInfo = (params) => get(`api/doctor-platform/oplog/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`)

// 获取登录验证码
export const sendSMS = (params) => post('api/doctor-platform/sendSMS', params)

// 查询用户权限列表
export const getUserAuthList = () => get('/api/doctor-platform/auth/user/perms')

// 获取编辑医生信息
export const getEditDoctorInfo = (id) => get(`api/doctor-platform/doctor/info?doctorId=${id}`)
