import { post, get } from '@http/axios'

// 查询角色列表
export const getRoleList = () => get('/api/doctor-platform/auth/role/list')

// 新增角色
export const addRole = (params) => post('/api/doctor-platform/auth/role/add', params)

// 编辑角色
export const editRole = (params) => post('/api/doctor-platform/auth/role/edit', params)

// 删除角色
export const deleteRole = (id) => post(`/api/doctor-platform/auth/role/delete/${id}`)

// 查询用户角色授权信息列表
export const getUserList = (id) => get(`/api/doctor-platform/auth/user-role-auth/list?roleId=${id}`)

// 查询角色权限列表
export const getRolePermsList = (id) => get(`/api/doctor-platform/auth/role/perms?roleId=${id}`)

// 保存角色授权(权限和用户)信息
export const saveRoleAuth = (params) => post('/api/doctor-platform/auth/role/role-auth-save', params)
