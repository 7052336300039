export const genderEnums = [ // 性别映射表
  {
    code: '1',
    text: '男'
  }, {
    code: '2',
    text: '女'
  }
]

export const assessResult = { // 问卷结果映射表
  1: ['正常', '轻度焦虑', '中度焦虑', '重度焦虑'],
  2: ['正常', '轻度抑郁', '中度抑郁', '重度抑郁'],
  3: ['您不易焦虑，易表现出安定的状态。', '', '您较易焦虑，易表现出烦躁、不安静和神经过敏。'],
  4: ['您的抑郁程度较弱，生活态度乐观积极，充满活力，心境愉快。', '', '您的抑郁程度较强，生活缺乏足够的兴趣，缺乏运动活力。']
}

export const followUpStatus = { // 随访状态映射表
  0: '未开始',
  1: '进行中',
  2: '完成',
  3: '超窗'
}
/**
 *  植入信息部分字典
 * */
// 植入部位
export const implantSizeDict = [
  {
    code: 1,
    text: '左侧'
  },
  {
    code: 2,
    text: '右侧'
  },
  {
    code: 3,
    text: '其他'
  }
]
// 适应证
export const indicationsDict = [
  {
    code: 0,
    text: '心动过缓'
  },
  {
    code: 1,
    text: '心力衰竭'
  },
  {
    code: 2,
    text: '室性心律失常'
  },
  {
    code: 3,
    text: '其他'
  }
]
// 适应证 - 心动过缓 - 选项字典
export const bradycardiaRadioDict = [
  {
    code: 0,
    text: '病态窦房结综合征'
  },
  {
    code: 1,
    text: '房室传导阻滞'
  },
  {
    code: 2,
    text: '房颤伴慢室率'
  }
]
// 适应证 - 心律失常 - 选项字典
export const arrhythmiaRadioDict = [
  {
    code: 0,
    text: '室性心动过速'
  },
  {
    code: 1,
    text: '心室颤动'
  },
  {
    code: 2,
    text: '室性期前收缩'
  }
]
// CIED类型字典
export const ciedTypeDict = [
  {
    code: 0,
    text: '单腔起搏器'
  },
  {
    code: 1,
    text: '双腔起搏器'
  },
  {
    code: 2,
    text: '单腔ICD'
  },
  {
    code: 3,
    text: '双腔ICD'
  },
  {
    code: 4,
    text: 'CRT-P'
  },
  {
    code: 5,
    text: 'CRT-D'
  },
  {
    code: 6,
    text: '其他'
  }
]
// 品牌dict
export const brandDict = [
  {
    code: 0,
    text: '美敦力'
  },
  {
    code: 1,
    text: '雅培'
  },
  {
    code: 2,
    text: '百多力'
  },
  {
    code: 3,
    text: '波科'
  },
  {
    code: 4,
    text: '其他'
  }
]
// 心房导线 - 植入部位 - 选项dict
export const atrialImplantPositionDict = [
  {
    code: 0,
    text: '右心耳'
  },
  {
    code: 1,
    text: '右心房侧壁'
  }
]
// 右心室导线 - 植入部位 - 选项dict
export const rightImplantPositionDict = [
  {
    code: 0,
    text: '右室心尖部'
  },
  {
    code: 1,
    text: '右室间隔部'
  },
  {
    code: 2,
    text: '希氏束区域'
  },
  {
    code: 3,
    text: '左束支区域'
  }
]
// 左心室导线 - 植入部位 - 选项dict
export const leftImplantPositionDict = [
  {
    code: 0,
    text: '侧后静脉'
  },
  {
    code: 1,
    text: '侧静脉'
  },
  {
    code: 2,
    text: '其他'
  }
]
// 起搏模式字典
export const pacemakerModelDict = [
  {
    code: 1,
    text: 'AAI(R)'
  },
  {
    code: 2,
    text: 'VVI(R)'
  },
  {
    code: 3,
    text: 'DDD(R)'
  }
]
// 围术期不良事件字典
export const hasEventDict = [
  {
    code: -1,
    text: '-'
  },
  {
    code: 0,
    text: '无'
  },
  {
    code: 1,
    text: '有'
  }
]

export const adverseEventDict = [
  {
    code: 1,
    text: '气胸'
  },
  {
    code: 2,
    text: '血胸'
  },
  {
    code: 3,
    text: '血气胸'
  },
  {
    code: 4,
    text: '导线穿孔'
  },
  {
    code: 5,
    text: '导线脱落'
  },
  {
    code: 6,
    text: '感染'
  },
  {
    code: 7,
    text: '导线重置'
  },
  {
    code: 8,
    text: '血肿'
  },
  {
    code: 9,
    text: '其他'
  }
]

// /**
//  *  权限字典
//  * */
// export const permissionDict = [
//   /**
//   * 医院管理
//   */
//   {
//     key: 'HOSPITAL_MGR_CHANGE_HOSPITAL_STATUS',
//     code: 'hospital-mgr:change-hospital-status',
//     name: '医院管理-更改医院状态'
//   },
//   {
//     key: 'HOSPITAL_MGR_CHANGE_DOCTOR_STATUS',
//     code: 'hospital-mgr:change-doctor-status',
//     name: '医院管理-更改医生状态'
//   }
// ]

export const permissionDict = [
  {
    code: 'audit-patient:access',
    label: '待审核患者（菜单权限）',
    children: [{
      code: 'audit-patient:audit-patient',
      label: '审核患者信息'
    }]
  }, {
    code: 'verify-task:access',
    label: '待处理任务（菜单权限）',
    children: [{
      code: 'verify-task:verify-data',
      label: '校对数据'
    }]
  }, {
    code: 'patient-mgr:access',
    label: '患者管理（菜单权限）',
    children: [{
      code: 'patient-mgr:query',
      label: '查询'
    }, {
      code: 'patient-mgr:add-patient',
      label: '添加患者'
    }, {
      code: 'patient-mgr:upload-report',
      label: '上传程控随访PDF'
    }, {
      code: 'patient-mgr:patient-archive',
      label: '患者档案',
      children: [{
        code: 'patient-mgr:add-manual-period',
        label: '新增自建随访'
      }, {
        code: 'patient-mgr:delete-manual-period',
        label: '删除'
      }, {
        code: 'patient-mgr:edit-followup',
        label: '编辑随访信息'
      }, {
        code: 'patient-mgr:edit-patient-archive',
        label: '编辑患者档案(基线信息)'
      }, {
        code: 'patient-mgr:view-more',
        label: '查看更多'
      }]
    }]
  }, {
    code: 'statistics:access',
    label: '数据统计（菜单权限）',
    children: [{
      code: 'statistics:query',
      label: '查询'
    }]
  }, {
    code: 'system-settings:access',
    label: '系统设置（菜单权限）',
    children: [{
      code: 'data-verify-settings:access',
      label: '校对数据配置（菜单权限）',
      children: [{
        code: 'data-verify-settings:query',
        label: '查询'
      }, {
        code: 'data-verify-settings:set-up',
        label: '设置'
      }]
    }, {
      code: 'hospital-mgr:access',
      label: '医院管理（菜单权限）',
      children: [
      //   {
      //   code: 'hospital-mgr:add-hospital',
      //   label: '新增医院'
      // }, {
      //   code: 'hospital-mgr:change-hospital-status',
      //   label: '医院状态'
      // },
        {
          code: 'hospital-mgr:edit-hospital',
          label: '编辑'
        }, {
          code: 'hospital-mgr:download-qr-code',
          label: '下载二维码'
        },
        {
          code: 'hospital-mgr:manage-doctor',
          label: '医生管理',
          children: [
            {
              code: 'hospital-mgr:add-doctor',
              label: '医生开户'
            }, {
              code: 'hospital-mgr:edit-doctor',
              label: '编辑医生'
            }, {
              code: 'hospital-mgr:change-doctor-status',
              label: '医生状态'
            }
          ]
        }
      ]
    }, {
      code: 'device-mgr:access',
      label: '设备管理（菜单权限）',
      children: [{
        code: 'device-mgr:add-model',
        label: '新增型号'
      }, {
        code: 'device-mgr:edit-device',
        label: '编辑'
      }, {
        code: 'device-mgr:delete-device',
        label: '删除'
      }, {
        code: 'device-mgr:query-device',
        label: '查询'
      }]
    }, {
      code: 'permission-mgr:access',
      label: '权限管理（菜单权限）',
      children: [{
        code: 'permission-mgr:add-role',
        label: '新增角色'
      }, {
        code: 'permission-mgr:edit-role',
        label: '编辑'
      }, {
        code: 'permission-mgr:delete-role',
        label: '删除'
      }]
    }, {
      code: 'operation-log:access',
      label: '操作日志（菜单权限）'
    }, {
      code: 'password-change:access',
      label: '更改密码（菜单权限）'
    }]
  }]
