<template>
  <div class="permission-manage-content">
    <part-title title="权限管理"></part-title>
    <el-button v-if="editRoleHandle" class="content-btn" size="small" type="primary" @click="handleSave">
      保存
    </el-button>
    <div class="content-modular">
      <div class="modular-role">
        <div class="role-title" @click="handleAddRole">
          <span class="title-span">角色</span>
          <div v-if="addRoleHandle" class="title-operation">
            <i class="el-icon-circle-plus"></i>
            新增角色
          </div>
        </div>
        <ul class="role-content">
          <div
            type="text"
            v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            @click="handleChangeRole(item.id)">
            <div :class="roleCheckedId === item.id ? 'content-item-checked' :'content-item'">
              <div
              :class="roleCheckedId === item.id ? 'item-name-checked' :'item-name'">{{item.name}}</div>
              <div class="item-operation">
                <el-button
                v-if="editRoleHandle"
                :class="roleCheckedId === item.id ? 'operation-edit-checked' :'operation-edit'"
                  type="text"
                  @click="handleEdit(item)">
                  编辑
                </el-button>
                <el-button
                v-if="deleteRoleHandle"
                  :class="roleCheckedId === item.id ? 'operation-delete-checked' :'operation-delete'"
                  type="text"
                  @click="handleDelete(item)">
                  删除
                </el-button>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div class="modular-user">
        <div class="user-title">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            class="user-checkbox">
            <span class="title-span">选择用户</span>
          </el-checkbox>
          <div class="title-operation-user">
            {{doctorIds.length}}/{{userList.length}}
          </div>
        </div>
        <ul class="user-content">
          <el-scrollbar style="height: 100%">
            <el-checkbox-group v-model="doctorIds" @change="handleCheckedUserChange">
              <el-checkbox
                class="content-item"
                v-for="i in userList"
                :label="i.doctorId"
                :key="i.doctorId">
                {{i.doctorName}},{{i.username}}
            </el-checkbox>
            </el-checkbox-group>
          </el-scrollbar>
        </ul>
      </div>
      <div class="modular-permission">
        <div class="permission-title">
          <el-checkbox
            :indeterminate="isIndeterminatePermission"
            v-model="checkAllPermission"
            @change="handleCheckAllPermission">
            <span class="title-span">权限分配</span>
          </el-checkbox>
        </div>
        <el-scrollbar class="permission-content" astyle="height: 100%">
          <el-tree
            :data="permissionData"
            show-checkbox
            ref="tree"
            default-expand-all
            highlight-current
            node-key="code"
            @check-change="handleCheckChange" >
          </el-tree>
        </el-scrollbar>
      </div>
    </div>
    <el-drawer
      :title="`${isEditDrawer ? '编辑': '新增'}角色信息`"
      :visible.sync="isVisibleAddRoleDrawer"
      :wrapperClosable="false"
      :close-on-press-escape="false">
      <div style="margin: 24px">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          label-position="left">
          <el-form-item label="角色名称" prop="name">
            <el-input v-model="ruleForm.name" @input="handleInput" maxlength="8" placeholder="请输入角色名称"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
        <el-button class="footer-btn" @click="isVisibleAddRoleDrawer = false">取消</el-button>
        <el-button class="footer-btn-save" type="primary" @click="handelAdd">{{`${isEditDrawer ? '保存': '新增'}`}}</el-button>
      </div>
      </div>
    </el-drawer>

  </div>
</template>
<script>
import PartTitle from '@/components/Part'
import { getRoleList, addRole, editRole, deleteRole, getUserList, getRolePermsList, saveRoleAuth } from '@/service/module/permission'
import { getUserAuthList } from '@/service/module/common'
import { permissionDict } from '@/utils/enums'
// import { map } from 'utility'
export default {
  components: {
    PartTitle
  },
  data () {
    return {
      roleList: [],
      userList: [],
      checkAll: false,
      checkAllPermission: false,
      roleId: null,
      doctorIds: [],
      codes: [],
      isIndeterminate: false,
      isIndeterminatePermission: false,

      permissionData: permissionDict,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      isEditDrawer: false,
      ruleForm: {},
      isVisibleAddRoleDrawer: false,
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5]+$/, message: '请输入中文' }
        ]
      },
      checkedRoleItem: false,

      addRoleHandle: false,
      editRoleHandle: false,
      deleteRoleHandle: false,

      // roleChecked: false,
      roleCheckedId: null,

      twoMenuTempleteCount: 0,
      threeMenuTempleteCount: 0,

      saveParmas: [],
      codeList: [],
      fanxianParmas: [],

      menuList: [],
      btnList: [],

      daichulilist: [],
      completeCodeList: [
        'patient-mgr:access',
        'patient-mgr:patient-archive',
        'patient-mgr:view-more',
        'statistics:access',
        'statistics:query',
        'system-settings:access',
        'data-verify-settings:access',
        'data-verify-settings:query',
        'data-verify-settings:set-up',
        'hospital-mgr:access',
        'hospital-mgr:edit-hospital',
        'hospital-mgr:download-qr-code',
        'hospital-mgr:manage-doctor',
        'hospital-mgr:add-doctor',
        'hospital-mgr:edit-doctor',
        'hospital-mgr:change-doctor-status',
        'device-mgr:access',
        'device-mgr:add-model',
        'device-mgr:edit-device',
        'device-mgr:delete-device',
        'device-mgr:query-device',
        'permission-mgr:access',
        'permission-mgr:add-role',
        'permission-mgr:edit-role',
        'permission-mgr:delete-role',
        'password-change:access',
        'operation-log:access',
        'patient-mgr:query',
        'patient-mgr:add-patient',
        'patient-mgr:upload-report',
        'patient-mgr:add-manual-period',
        'patient-mgr:delete-manual-period',
        'patient-mgr:edit-followup',
        'patient-mgr:edit-patient-archive',
        'audit-patient:access',
        'audit-patient:audit-patient',
        'verify-task:access',
        'verify-task:verify-data'
      ]
    }
  },
  created () {
    getRoleList().then(res => {
      if (res && res.code === 200) {
        this.roleList = res.data
        this.roleList.reverse()
        if (this.roleList && this.roleList.length > 0) {
          this.handleChangeRole(this.roleList[0].id)
          this.roleCheckedId = this.roleList[0].id
        }
      } else {
        this.$message.error(res.message)
      }
    })
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'permission-mgr:add-role') {
        this.addRoleHandle = true
      }
      if (i === 'permission-mgr:edit-role') {
        this.editRoleHandle = true
      }
      if (i === 'permission-mgr:delete-role') {
        this.deleteRoleHandle = true
      }
    })
  },
  methods: {
    getRoleList () {
      getRoleList().then(res => {
        if (res) {
          this.roleList = res.data
          this.roleList.reverse()
        }
      })
    },
    handleCheckAllChange (val) {
      this.doctorIds = []
      if (val) {
        this.userList.map(itemer => {
          this.doctorIds.push(itemer.doctorId)
        })
      }
      this.isIndeterminate = false
    },
    handleCheckedUserChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.userList ? this.userList.length : []
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.userList.length
    },
    handleCheckAllPermission (value) {
      this.checkAllPermission = value
      this.codes = []
      if (this.checkAllPermission) {
        this.codes = this.completeCodeList
        this.codeList = this.completeCodeList
        // this.permissionData.map(i => {
        //   if (i.children) {
        //     i.children.map(itemer => {
        //       this.codes.push(itemer.code)
        //       if (itemer.children) {
        //         itemer.children.map(item => {
        //           this.codes.push(item.code)
        //         })
        //       }
        //     })
        //   } else {
        //     this.codes.push(i.code)
        //   }
        // })
        // console.log('codelist:', this.codes)
        this.$refs.tree.setCheckedKeys(this.codes)
      } else {
        this.codeList = []
        this.$refs.tree.setCheckedKeys([])
      }
    },
    handleAddRole () {
      this.isVisibleAddRoleDrawer = true
      this.ruleForm = {}
    },
    handelAdd () {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.isEditDrawer) {
            editRole(this.ruleForm).then(res => {
              if (res.code === 200) {
                this.$message.success('保存成功！')
                this.isVisibleAddRoleDrawer = false
                this.getRoleList()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            addRole(this.ruleForm).then(res => {
              if (res.code === 200) {
                this.$message.success('保存成功！')
                this.isVisibleAddRoleDrawer = false
                this.getRoleList()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },
    handleEdit (i) {
      this.ruleForm.name = i.name
      this.ruleForm.roleId = i.id
      this.isEditDrawer = true
      this.isVisibleAddRoleDrawer = true
    },
    handleDelete (i) {
      this.$confirm('这信息删除后无法恢复，确定删除吗？', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteRole(i.id).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.message)
          } else {
            this.getRoleList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          }
        }).catch(err => {
          this.$message.error('网络错误')
          throw new Error(err)
        })
      })
    },
    handleInput () {
      this.$forceUpdate()
    },
    handleChangeRole (id) {
      // this.codeList = []
      this.twoMenuTempleteCount = 0
      this.threeMenuTempleteCount = 0
      this.roleCheckedId = id
      this.doctorIds = []
      this.roleId = id
      this.roleList.map(i => {
        if (id === i.id) {
          this.checkedRoleItem = true
        } else {
          this.checkedRoleItem = false
        }
      })
      getUserList(id).then(res => {
        if (res) {
          this.userList = res.data
          this.userList.map(itemer => {
            if (itemer.auth) {
              this.doctorIds.push(itemer.doctorId)
            }
          })
          if (this.doctorIds && this.doctorIds.length > 0) {
            this.isIndeterminate = true
          } else {
            this.isIndeterminate = false
          }
        }
      })
      getRolePermsList(id).then(res => {
        if (res) {
          this.codeList = []
          this.codeList = res.data.perms
          if (res.data.perms.length >= 38) {
            this.checkAllPermission = true
          } else {
            this.checkAllPermission = false
          }
          // 删除一级二级菜单
          this.permissionData.map(oneItem => {
            if (oneItem.children && oneItem.children.length > 1) {
              oneItem.children.map(twoItem => {
                if (this.codeList.indexOf(twoItem.code) === -1) {
                  console.log('没找到二级:', twoItem.code, this.codeList)
                  if (this.codeList.indexOf(oneItem.code) !== -1) {
                    this.codeList.splice(this.codeList.indexOf(oneItem.code), 1)
                  }
                }
                if (twoItem.children && twoItem.children.length > 1) {
                  twoItem.children.map(threeItem => {
                    // 没找到
                    // console.log('000:', this.codeList, threeItem.code)
                    if (this.codeList.indexOf(threeItem.code) === -1) {
                      // this.daichulilist.push(threeItem.code)
                      // console.log('111:', threeItem.code)
                      if (this.codeList.indexOf(twoItem.code) !== -1) {
                        // console.log('222:', twoItem.code)
                        this.codeList.splice(this.codeList.indexOf(twoItem.code), 1)
                      }
                      if (this.codeList.indexOf(oneItem.code) !== -1) {
                        // console.log('333:', oneItem.code)
                        this.codeList.splice(this.codeList.indexOf(oneItem.code), 1)
                      }
                    }
                  })
                }
              })
            }
          })
          console.log('元数据列表：', this.codeList)
          this.getMenuList(this.codeList)
          this.$refs.tree.setCheckedKeys(this.codeList)
        }
      })
    },
    getMenuList (list) {
      this.menuList = []
      this.btnList = []
      list.map(i => {
        if (i.indexOf('access') !== -1) {
          this.menuList.push(i)
        } else {
          this.btnList.push(i)
        }
      })
      console.log('menu:', this.menuList)
      console.log('button:', this.btnList)
    },
    // 去重：
    removal (list) {
      for (var i = 0; i < list.length; i++) {
        for (var j = i + 1; j < list.length; j++) {
          if (list[i] === list[j]) {
            this.$delete(list, j)
            j--
          }
        }
      }
    },
    handleSave () {
      this.removal(this.codeList)
      this.permissionData.map(oneItem => {
        if (oneItem.children && oneItem.children.length > 1) {
          oneItem.children.map(twoItem => {
            if (this.codeList.indexOf(twoItem.code) !== -1) {
              if (this.codeList.indexOf(oneItem.code) === -1) {
                this.saveParmas.push(oneItem.code)
              }
            }
            if (twoItem.children && twoItem.children.length > 1) {
              twoItem.children.map(threeItem => {
                if (this.codeList.indexOf(threeItem.code) !== -1) {
                  // this.daichulilist.push(threeItem.code)
                  if (this.codeList.indexOf(twoItem.code) === -1) {
                    this.saveParmas.push(twoItem.code)
                  }
                  if (this.codeList.indexOf(oneItem.code) === -1) {
                    this.saveParmas.push(oneItem.code)
                  }
                }
              })
            }
          })
        }
      })
      // 保存合并数据
      const saveData = this.codeList
      this.saveParmas.map(i => {
        if (saveData.indexOf(i) === -1) {
          saveData.push(i)
        }
      })
      this.removal(saveData)
      console.log('权限列表：', saveData)
      saveRoleAuth({
        roleId: this.roleId,
        doctorIds: this.doctorIds,
        codes: saveData
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功！')

          this.getUserAuthList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleCheckChange (e, currentIsChecked) {
      this.removal(this.codeList)
      if (currentIsChecked) {
        if (this.codeList.indexOf() === -1) {
          this.codeList.push(e.code)
        }
        if (this.codeList.length >= 38) {
          this.checkAllPermission = true
        } else {
          this.checkAllPermission = false
        }
      } else {
        this.checkAllPermission = false
        if (this.codeList.indexOf(e.code) !== -1) {
          this.codeList.splice(this.codeList.indexOf(e.code), 1)
        }
      }
    },
    async getUserAuthList () {
      try {
        const res = await getUserAuthList()
        sessionStorage.setItem('auth', JSON.stringify(res.data))
        window.location.reload()
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.permission-manage-content {
  margin: 0 20px;
  .content-btn {
    float: right;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .content-modular {
    display: flex;
    justify-content:space-between;
    margin-top: 46px;
    width:100%;
    .modular-role,.modular-user,.modular-permission {
      width: 100%;
      .role-title,.user-title,.permission-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        // width: 263px;
        height: 40px;

        background: #F2F6FC;
        border: 1px solid #DCDFE6;
        border-radius: 4px 0 0 0;
        .title-span {
          color: #606266;
          font-weight: 400;
          font-size: 16px;
        }
        .el-icon-circle-plus {
          width: 14px;
          height: 14px;
          margin-right: 3px;
        }
        .title-operation {
          color: #409EFF;
          font-size: 14px;
        }
        .title-operation-user {
          color: #909399;
          font-size: 12px;
        }
      }
      .role-title{
        cursor:pointer;
      }
      .role-content,.user-content,.permission-content {
        height: 509px;
        border-bottom: 1px solid #DCDFE6;
        border-right: 1px solid #DCDFE6;
        border-radius: 0px 0px 0px 4px;
        .content-item,.content-item-checked  {
          cursor:pointer;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: #606266;
          font-weight: 400;
          font-size: 14px;
          padding: 0 16px;
          .item-name,.item-name-checked {
            display: inline-block;
            height: 56px;
            line-height: 56px;
          }
          .item-name-checked {
            color: #FFFFFF;
            font-size: 14px;
          }
          .item-operation {
            display: inline-block;
            .operation-edit,.operation-delete {
              color: #409EFF;
              font-weight: 400;
              font-size: 12px;
              line-height: 30px;
            }
            .operation-delete {
              color: #F56C6C;
              margin-left: 10px;
            }
          }
        }
        .content-item-checked {
          background: #409EFF;
          .operation-edit-checked,.operation-delete-checked {
            color: #F5F7FA;
              font-weight: 400;
              font-size: 12px;
              line-height: 30px;
            }
            .operation-delete-checked {
              margin-left: 10px;
            }
        }
        .content-item:hover {
          background: #F5F7FA;
        }
        .content-item.is-active {
          background: #337ECC !important;
        }
      }
      .role-content {
        border-left: 1px solid #DCDFE6;
      }
    }
  }
}
::v-deep .el-button+.el-button {
 margin-left: 0px;
}
.user-checkbox {
  width: 100%;
  line-height: 40px;
}
::v-deep .el-checkbox-group {
  .el-checkbox:hover {
    line-height: 40px;
    height: 40px;
    background: #F5F7FA;
  }
  .el-checkbox__input {
    padding-top:13px;
  }
  .el-checkbox__label {
    line-height: 40px;
    font-size: 14px;
  }
}
::v-deep .el-checkbox {
  width: 12px;
}
::v-deep .el-tree-node__content {
  margin-left: 10px;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  right: 20px;
  .footer-btn-save {
    margin-left: 12px;
  }
}
::v-deep .part-box .part-content[data-v-1ce9dea3] {
  margin-bottom: 0px;
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}
</style>
